@font-face {
    font-family: 'AudiTypeScreen';
    src: url('../fonts/AudiTypeVF.woff2');
    font-display: swap;
}

@font-face {
    font-family: 'AudiTypeExtended';
    src: url('../fonts/AudiTypeExtended-Normal.eot');
    src: url('../fonts/AudiTypeExtended-Normal.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AudiType-ExtendedNormal-l.woff2') format('woff2'),
    url('../fonts/AudiTypeExtended-Normal.woff') format('woff'),
    url('../fonts/AudiTypeExtended-Normal.ttf')  format('truetype'),
    url('../fonts/AudiTypeExtended-Normal.svg#AudiTypeExtended-Normal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

